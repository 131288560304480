import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Empty from "../components/empty"
import TermsAndConditions from "../components/terms"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Terms of Service" />
      <TermsAndConditions />
    </Layout>
  )
}

export default IndexPage
